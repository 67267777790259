@media only screen and (max-width: 600px) {
    .fc .fc-button {
        padding: 0;
    }
    .fc .fc-toolbar-title {
        font-size: 1.2em
    }
}

.fc .fc-timegrid
{
    background-color: white;
}
